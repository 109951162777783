import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import sagas from './sagas';

import AccountSetup from './components/AccountSetup';
import ClinicSetup from './components/ClinicSetup';


const components = {
  AccountSetup,
  ClinicSetup,
};


export default {
  actions,
  components,
  constants,
  selectors,
  sagas,
};
