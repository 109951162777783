import React from 'react';
import SessionStorage from 'libs/SessionStorage';
import Account from 'modules/Account';
import Layout from 'modules/Layout';


function action({ route, query, store }) {
  if (process.env.BROWSER) {
    if (query.returnPathname) {
      SessionStorage.setItem('returnPathname', query.returnPathname);
    }
    store.dispatch(Account.actions.authCallback(query.code));
  }

  return {
    chunks   : ['account'],
    name     : route.name,
    // redirect,
    component: (
      <Layout.components.LoaderLayout />
    ),
  };

}

export default action;
