import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppContext } from 'context';
import Account from 'modules/Account';
import ImplicitProfileSetup from './ImplicitProfileSetup';
import PatientSetup from './PatientSetup';


class AccountSetup extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    enrollCode       : PropTypes.string,
    invitationCode   : PropTypes.string,
    // Implicit props
    activeProfileType: Account.shapes.profileType,
    // Implicit actions
  };


  render() {
    const { activeProfileType, enrollCode, invitationCode } = this.props;
    if (
      activeProfileType === Account.constants.PROFILE_TYPES.HCP
      || activeProfileType === Account.constants.PROFILE_TYPES.CG
    ) {
      return <ImplicitProfileSetup activeProfileType={activeProfileType} />;
    }
    return <PatientSetup enrollCode={enrollCode} invitationCode={invitationCode} />;
  }

}


const mapStateToProps = (state) => ({
  activeProfileType: Account.selectors.activeProfileType(state),
});


const ConnectedAccountSetup = connect(
  mapStateToProps,
)(AccountSetup);


export default ConnectedAccountSetup;
