import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import Loader from 'svg/loader.svg';
import urlRouteShape from 'shapes/urlRouteShape';
import Account from 'modules/Account';
import App from 'modules/App';
import history from 'helpers/history';
import styles from './ClinicSetup.pcss';


class ClinicSetup extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeProfileType        : Account.shapes.profileType, // eslint-disable-line react/no-unused-prop-types
    // Implicit props
    isInProgress             : PropTypes.bool,
    hcpProfile               : PropTypes.object,
    activeProfileMainUrlRoute: urlRouteShape,
    hasErrors                : PropTypes.bool,
    // Implicit actions
    onValidateMemberships    : PropTypes.func,
  };


  componentDidMount() {
    this.props.onValidateMemberships();
  }


  componentDidUpdate(prevProps) {
    const { activeProfileMainUrlRoute, isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      const { name, params } = activeProfileMainUrlRoute;
      const url = this.context.getUrl(name, params);
      history.push(url);
    }
  }


  renderLoader() {
    if (this.props.hasErrors) {
      return 'Has errors';
    }
    return (
      <div className={styles.loaderContainer}>
        <Loader className={cn(styles.loader, 'rotatingLoader')} />
      </div>
    );
  }


  render() {
    return (
      <div>
        <header className="pageHeader">
          <h1 className="text--h1"><FormattedMessage {...Account.messages.headers.configuringClinic} /></h1>
        </header>
        <App.components.AlertsBus className="mb-5" />
        { this.renderLoader() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeProfileMainUrlRoute: Account.selectors.activeProfileMainUrlRoute(state),
  isInProgress             : Account.selectors.isValidateMembershipInProgress(state),
  hasErrors                : Account.selectors.hasValidateMembershipErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onValidateMemberships: () => dispatch(Account.actions.validateMemberships()),
});


const ConnectedClinicSetup = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicSetup);


export default withStyles(styles)(ConnectedClinicSetup);
