import React from 'react';
import { getAsyncInjectors } from 'helpers/asyncInjectors';
import AccountSetup from 'modules/AccountSetup';
import Layout from 'modules/Layout';


function action({ route, store }) {
  const { injectSagas } = getAsyncInjectors(store);
  injectSagas(AccountSetup.constants.MODULE_ID, AccountSetup.sagas);

  return {
    chunks   : ['account'],
    name     : route.name,
    component: (
      <Layout.components.PlainLayout>
        <AccountSetup.components.ClinicSetup />
      </Layout.components.PlainLayout>
    ),
  };

}

export default action;
