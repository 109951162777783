import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import Loader from 'svg/loader.svg';
import urlRouteShape from 'shapes/urlRouteShape';
import Account from 'modules/Account';
import App from 'modules/App';
import history from 'helpers/history';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import styles from './ImplicitProfileSetup.pcss';


class ImplicitProfileSetup extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    activeProfileType        : Account.shapes.profileType, // eslint-disable-line react/no-unused-prop-types
    // Implicit props
    isInProgress             : PropTypes.bool,
    hcpProfile               : PropTypes.object,
    activeProfileMainUrlRoute: urlRouteShape,
    hasErrors                : PropTypes.bool,
    // Implicit actions
    onCreateProfile          : PropTypes.func,
  };


  componentDidMount() {
    this.props.onCreateProfile();
  }


  componentDidUpdate(prevProps) {
    const { activeProfileMainUrlRoute, isInProgress, hasErrors } = this.props;
    if (prevProps.isInProgress !== isInProgress && !isInProgress && !hasErrors) {
      const { name, params } = activeProfileMainUrlRoute;
      const url = this.context.getUrl(name, params);
      history.push(url);
    }
  }


  renderLoader() {
    if (this.props.hasErrors) {
      return 'Has errors';
    }
    return (
      <div className={styles.loaderContainer}>
        <Loader className={cn(styles.loader, 'rotatingLoader')} />
      </div>
    );
  }


  render() {
    return (
      <div>
        <header className="pageHeader">
          <h1 className="text--h1"><FormattedMessage {...Account.messages.headers.configuringProfile} /></h1>
        </header>
        <App.components.AlertsBus className="mb-5" />
        { this.renderLoader() }
      </div>
    );
  }

}


const mapStateToProps = (state, props) => {
  const { activeProfileType } = props;
  let isCreateProfileInProgress;
  let hasCreateProfileErrors;
  if (activeProfileType === Account.constants.PROFILE_TYPES.HCP) {
    isCreateProfileInProgress = selectors.isCreateHcpProfileInProgress;
    hasCreateProfileErrors = selectors.hasCreateHcpProfileErrors;
  } else if (activeProfileType === Account.constants.PROFILE_TYPES.CG) {
    isCreateProfileInProgress = selectors.isCreateCgProfileInProgress;
    hasCreateProfileErrors = selectors.hasCreateCgProfileErrors;
  }
  return {
    isInProgress             : isCreateProfileInProgress(state) || Account.selectors.isValidateMembershipInProgress(state),
    hcpProfile               : Account.selectors.hcpProfile(state),
    activeProfileMainUrlRoute: Account.selectors.activeProfileMainUrlRoute(state),
    hasErrors                : hasCreateProfileErrors(state),
  };
};


const mapDispatchToProps = (dispatch, props) => {
  const { activeProfileType } = props;
  let createProfile;
  if (activeProfileType === Account.constants.PROFILE_TYPES.HCP) {
    createProfile = actions.createHcpProfile;
  } else if (activeProfileType === Account.constants.PROFILE_TYPES.CG) {
    createProfile = actions.createCgProfile;
  }
  return {
    onCreateProfile: () => dispatch(createProfile()),
  };
};


const ConnectedImplicitProfileSetup = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImplicitProfileSetup);


export default withStyles(styles)(ConnectedImplicitProfileSetup);
