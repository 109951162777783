import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { AppContext } from 'context';
import MetricConversions from 'libs/MetricConversions';
import Validator from 'libs/Validator';
import { getStandards } from 'helpers/settings';
import intlShape from 'shapes/intlShape';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import AutosuggestInput from 'components/Form/AutosuggestInput';
import Button from 'components/Form/Button';
import PhiSetFormPartial from 'components/PhiSetFormPartial';
import countrySettingsShape from 'shapes/countrySettingsShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import ContourCloud from 'modules/ContourCloud';
import history from 'helpers/history';
import * as actions from '../../../../actions';
import * as constants from '../../../../constants';
import * as selectors from '../../../../selectors';
import styles from '../../Profile.pcss';
import validatorRules from './validatorRules.json';


class PatientProfile extends FormContainerAbstract {

  static contextType = AppContext;

  static getDerivedStateFromProps(props, state) {
    if (props.isInProgress !== state.isInProgress && (props.isInProgress || (!props.isInProgress && props.hasErrors))) {
      return {
        isInProgress: props.isInProgress,
      };
    }
    return null;
  }

  static propTypes = {
    // Explicit props
    enrollCode                     : PropTypes.string,
    invitationCode                 : PropTypes.string,
    // Implicit props
    ...FormContainerAbstract.propTypes,
    account                        : Account.shapes.account,
    accountCCAccessToken           : PropTypes.object,
    passphrase                     : PropTypes.string,
    patientProfile                 : PropTypes.object,
    authorizationCode              : CloudDrive.shapes.authorizationCode,
    patientHealthDataFromInvitation: Account.shapes.patientHealthDataFromInvitation,
    payers                         : PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    localizationResources          : PropTypes.object,
    route                          : App.shapes.route,
    metricsUnits                   : PropTypes.object,
    isClientInitialized            : PropTypes.bool,
    intl                           : intlShape,
    countrySettings                : countrySettingsShape,
    profileCC                      : PropTypes.object,
    activeProfileType              : Account.shapes.profileType,
    // Implicit actions
    onFetchCountrySettings         : PropTypes.func,
    onGetCCAccessToken             : PropTypes.func,
    onFetchCCProfile               : PropTypes.func,
    onFetchHealthDataFromInvitation: PropTypes.func,
    onAddPayerProposal             : PropTypes.func,
    onFetchPayers                  : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.metricConversions = new MetricConversions(props.metricsUnits);
    this.state = {
      isInProgress: false,
    };
    this.onPopulate();
  }


  componentDidMount() {
    const { account, passphrase } = this.props;
    this.props.onFetchPayers();
    this.props.onGetCCAccessToken(account, passphrase);
    this.props.onFetchCountrySettings();
    this.props.onFetchHealthDataFromInvitation();
  }


  componentDidUpdate(prevProps) {
    const { accountCCAccessToken, patientProfile, isInProgress } = this.props;
    if (prevProps.isInProgress !== isInProgress && patientProfile && patientProfile.encryptedExchangeToken) {
      history.push(this.context.getUrl('my-results'));
      return;
    }

    if (prevProps.accountCCAccessToken !== accountCCAccessToken && accountCCAccessToken) {
      this.props.onFetchCCProfile();
    }

    if (
      prevProps.patientHealthDataFromInvitation !== this.props.patientHealthDataFromInvitation
      && this.props.patientHealthDataFromInvitation
    ) {
      this.onPopulateHealthData();
    }

    if (this.props.profileCC && prevProps.profileCC !== this.props.profileCC) {
      this.onPopulate();
    }

  }


  componentWillUnmount() {
    if (this.props.route.name !== 'setup') this.props.onClearForm();
  }


  onPopulate() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }
    const { formValues } = this.props;
    const filledFormValues = get(formValues, 'values', {});
    const values = {
      ...filledFormValues,
      ...pick(this.props.profileCC, ['diabetesType', 'treatmentType']),
    };
    this.onSetValues(values);
  }


  onPopulateHealthData() {
    if (process.env.BROWSER && !this.props.isClientInitialized) {
      return;
    }
    const { patientHealthDataFromInvitation } = this.props;
    const { weight, height } = patientHealthDataFromInvitation;
    patientHealthDataFromInvitation.weight = weight && this.metricConversions.weight.toDisplay(weight);
    patientHealthDataFromInvitation.height = height && this.metricConversions.height.toDisplay(height);
    this.onSetValues(patientHealthDataFromInvitation);
  }


  onValidate(rules) {
    const values = { ...get(this.props.formValues, 'values', {}) };
    const clonedRules = { ...rules };

    if (this.isPayerMandatory) {
      clonedRules.payer = 'required';
    }

    return Validator.run(values, clonedRules);
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      const { weight, height } = validatedValues;
      validatedValues.weight = weight && this.metricConversions.weight.toStorage(weight);
      validatedValues.height = height && this.metricConversions.height.toStorage(height);
      const standards = getStandards(null, this.props.countrySettings);
      const { payer } = validatedValues;
      const { authorizationCode, enrollCode, invitationCode } = this.props;
      this.props.onSubmit(validatedValues, authorizationCode, standards, enrollCode, invitationCode);
      if (payer) {
        const { countryId } = this.props.account;
        this.props.onAddPayerProposal(countryId, payer);
      }
    }
  }


  get isPayerMandatory() {
    const { countrySettings } = this.props;
    return countrySettings ? countrySettings.isPayerMandatory : false;
  }


  get isDisabled() {
    const { authorizationCode } = this.props;
    const { errors } = this.onValidate(validatorRules);
    return !authorizationCode || !!errors;
  }


  get isDisabledTreatmentType() {
    const { profileCC } = this.props;
    if (!profileCC) return false;
    return Boolean(profileCC.treatmentType);
  }


  get isDisabledDiabetesType() {
    const { profileCC } = this.props;
    if (!profileCC) return false;
    return Boolean(profileCC.diabetesType);
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={Account.messages.buttons.submit}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.state.isInProgress}
            onClickDisabled={() => this.onCheck()}
          />
        </div>
      </div>
    );
  }


  renderPayer() {
    if (!this.isPayerMandatory) {
      return null;
    }
    return (
      <FormGroup
        id="payer"
        labelMessage={Account.messages.labels.payer}
        formValues={this.props.formValues}
      >
        <AutosuggestInput
          suggestions={this.props.payers}
          placeholder={Account.messages.placeholders.payer}
          onChange={(input) => this.onSetValue(input)}
        />
      </FormGroup>
    );
  }


  renderForm() {
    return (
      <Form onSubmit={() => this.onSubmit()}>
        <PhiSetFormPartial
          metricConversions={this.metricConversions}
          formValues={this.props.formValues}
          onSetFormValue={this.props.onSetFormValue}
          isDisabledDiabetesType={this.isDisabledDiabetesType}
          isDisabledTreatmentType={this.isDisabledTreatmentType}
          localizationResources={this.props.localizationResources}
          isActiveProfileTypePwd={Account.constants.PROFILE_TYPES.PWD === this.props.activeProfileType}
        />
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    return (
      <div data-testid="fill-health-info-section">
        <header className="pageHeader">
          <h1 className="text--h1"><FormattedMessage {...Account.messages.headers.fillHealthInformation} /></h1>
        </header>
        { this.renderAlerts() }
        { this.renderForm() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  isInProgress                   : selectors.isCreatePatientProfileInProgress(state),
  hasErrors                      : selectors.hasCreatePatientProfileErrors(state),
  account                        : Account.selectors.account(state),
  accountCCAccessToken           : Account.selectors.accountCCAccessToken(state),
  passphrase                     : Account.selectors.passphrase(state),
  patientProfile                 : Account.selectors.patientProfile(state),
  patientDataFromInvitation      : Account.selectors.patientDataFromInvitation(state),
  patientHealthDataFromInvitation: Account.selectors.patientHealthDataFromInvitation(state),
  payers                         : Account.selectors.payers(state),
  metricsUnits                   : Account.selectors.metricsUnits(state),
  countrySettings                : Account.selectors.countrySettings(state),
  activeProfileType              : Account.selectors.activeProfileType(state),
  isClientInitialized            : App.selectors.isClientInitialized(state),
  localizationResources          : App.selectors.localizationResources(state),
  formValues                     : App.selectors.formSelector(constants.PATIENT_PROFILE_FORM)(state),
  route                          : App.selectors.route(state),
  authorizationCode              : CloudDrive.selectors.authorizationCode(state),
  profileCC                      : ContourCloud.selectors.profile(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.PATIENT_PROFILE_FORM;

  return {
    onSubmit: (values, authorizationCode, standards, enrollCode, invitationCode) => dispatch(
      actions.createPatientProfile(values, authorizationCode, standards, enrollCode, invitationCode),
    ),
    onFetchHealthDataFromInvitation: () => dispatch(
      Account.actions.fetchPatientHealthDataFromInvitation(),
    ),
    onGetCCAccessToken: (account, passphrase) => dispatch(
      Account.actions.getCCAccessToken({ account, passphrase }),
    ),
    onFetchCountrySettings: () => dispatch(Account.actions.fetchCountrySettings()),
    onFetchPayers         : () => dispatch(Account.actions.fetchPayers()),
    onFetchCCProfile      : () => dispatch(ContourCloud.actions.getMe()),
    onAddPayerProposal    : (countryId, payer) => dispatch(App.actions.addPayerProposal(countryId, payer)),
    onSetFormValue        : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues       : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors          : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing      : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm           : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedPatientProfile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientProfile));


export default withStyles(styles)(ConnectedPatientProfile);
