import Account from 'modules/Account';


export const createCgProfile = () => ({
  type: Account.actionTypes.CREATE_CG_PROFILE,
});

export const createCgProfileSuccess = (cgProfile) => ({
  type   : Account.actionTypes.CREATE_CG_PROFILE_SUCCESS,
  payload: {
    cgProfile,
  },
});

export const createCgProfileError = (error) => ({
  type: Account.actionTypes.CREATE_CG_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const createHcpProfile = () => ({
  type: Account.actionTypes.CREATE_HCP_PROFILE,
});

export const createHcpProfileSuccess = (hcpProfile) => ({
  type   : Account.actionTypes.CREATE_HCP_PROFILE_SUCCESS,
  payload: {
    hcpProfile,
  },
});

export const createHcpProfileError = (error) => ({
  type: Account.actionTypes.CREATE_HCP_PROFILE_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const createPatientProfile = ({
  diabetesType,
  treatmentType,
  weight,
  height,
  sharingRequestId,
  payer,
} = {}, authorizationCode, standards, enrollCode = null, invitationCode = null) => ({
  type   : Account.actionTypes.CREATE_PATIENT_PROFILE,
  payload: {
    diabetesType,
    treatmentType,
    weight,
    height,
    sharingRequestId,
    payer,
    authorizationCode,
    standards,
    enrollCode,
    invitationCode,
  },
});

export const createPatientProfileSuccess = (patientProfile) => ({
  type   : Account.actionTypes.CREATE_PATIENT_PROFILE_SUCCESS,
  payload: {
    patientProfile,
  },
});

export const createPatientProfileError = (error) => ({
  type: Account.actionTypes.CREATE_PATIENT_PROFILE_ERROR,
  error,
});
