// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UtIeV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._2mjsy{width:225px;width:22.5rem;height:225px;height:22.5rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"loaderContainer": "UtIeV",
	"loader": "_2mjsy"
};
module.exports = exports;
