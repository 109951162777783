import React from 'react';
import OidcService from 'services/OidcService';


async function action({ route }) {

  if (process.env.BROWSER) {
    await OidcService.loginSilentCallback();
  }

  return {
    chunks   : ['account'],
    name     : route.name,
    component: <div />,
  };

}

export default action;
