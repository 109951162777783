import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import keyPairShape from 'shapes/keyPairShape';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import * as constants from '../../../../constants';


class AttachCloudDrive extends React.PureComponent {

  static propTypes = {
    // Implicit props
    keyPair        : keyPairShape,
    formValues     : PropTypes.object,
    // Implicit actions
    onSetFormValue : PropTypes.func,
    onSetFormValues: PropTypes.func,
  };


  render() {
    return (
      <div>
        <header className="pageHeader">
          <h1 className="text--h1"><FormattedMessage {...Account.messages.headers.setCloudDrive} /></h1>
          <p className="pageHeader__intro"><FormattedMessage {...Account.messages.intros.setCloudDrive} /></p>
        </header>
        <App.components.AlertsBus className="mb-5" />
        <CloudDrive.partials.SetCloudDrive
          type="pwd"
          keyPair={this.props.keyPair}
          formValues={this.props.formValues}
          onSetFormValue={this.props.onSetFormValue}
          onSetFormValues={this.props.onSetFormValues}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  keyPair   : Account.selectors.keyPair(state),
  formValues: App.selectors.formSelector(constants.PATIENT_PROFILE_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.PATIENT_PROFILE_FORM;
  return {
    onSetFormValue : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues: (values) => dispatch(App.actions.setFormValues(formName, values)),
  };
};


const ConnectedAttachCloudDrive = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachCloudDrive);


export default ConnectedAttachCloudDrive;
