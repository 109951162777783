import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import Account from 'modules/Account';


/**
 * Input selectors
 */
const sendingSelector = (state) => state[Account.constants.MODULE_ID].sending;
const errorsSelector = (state) => state[Account.constants.MODULE_ID].errors;


export const isCreateCgProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, Account.actionTypes.CREATE_CG_PROFILE),
);

export const hasCreateCgProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, Account.actionTypes.CREATE_CG_PROFILE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isCreateHcpProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, Account.actionTypes.CREATE_HCP_PROFILE),
);

export const hasCreateHcpProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, Account.actionTypes.CREATE_HCP_PROFILE),
);

//----------------------------------------------------------------------------------------------------------------------

export const isCreatePatientProfileInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, Account.actionTypes.CREATE_PATIENT_PROFILE),
);

export const hasCreatePatientProfileErrors = createSelector(
  errorsSelector,
  (state) => includes(state, Account.actionTypes.CREATE_PATIENT_PROFILE),
);
