import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { AppContext } from 'context';
import history from 'helpers/history';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import PatientProfile from './PatientProfile';
import AttachCloudDrive from './AttachCloudDrive';


class PatientSetup extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    enrollCode    : PropTypes.string,
    invitationCode: PropTypes.string,
    // Implicit props
    patientProfile: PropTypes.shape({
      encryptedExchangeToken: PropTypes.string,
    }),
    countrySettings: PropTypes.shape({
      isCentralStorageFixed: PropTypes.bool,
    }),
    authorizationCode    : CloudDrive.shapes.authorizationCode,
    // Implicit actions
    onAuthorizeCloudDrive: PropTypes.func,
  };


  constructor(props, context) {
    super(props);
    if (props.patientProfile && props.patientProfile.encryptedExchangeToken) {
      history.push(context.getUrl('my-results'));
    }
  }


  componentDidMount() {
    if (this.isCentralStorageFixed) {
      this.props.onAuthorizeCloudDrive();
    }
  }


  get isCentralStorageFixed() {
    return get(this.props.countrySettings, 'isCentralStorageFixed', false);
  }


  renderStep() {
    const encryptedExchangeToken = get(this.props.patientProfile, 'encryptedExchangeToken');
    if (!this.isCentralStorageFixed && !this.props.authorizationCode && !encryptedExchangeToken) {
      return <AttachCloudDrive />;
    }
    const { enrollCode, invitationCode } = this.props;
    return <PatientProfile enrollCode={enrollCode} invitationCode={invitationCode} />;
  }


  render() {
    return (
      <div>
        <Account.partials.LogoutBtn />
        { this.renderStep() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  authorizationCode: CloudDrive.selectors.authorizationCode(state),
  patientProfile   : Account.selectors.patientProfile(state),
  countrySettings  : App.selectors.countrySettings(state),
});


const mapDispatchToProps = (dispatch) => ({
  onAuthorizeCloudDrive: () => dispatch(CloudDrive.actions.authorize('CentralStorage', 'pwd')),
});


const ConnectedPatientSetup = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientSetup);


export default ConnectedPatientSetup;
