import React from 'react';
import startsWith from 'lodash/startsWith';
import { getAsyncInjectors } from 'helpers/asyncInjectors';
import { getSlug } from 'helpers/urlTools';
import Account from 'modules/Account';
import AccountSetup from 'modules/AccountSetup';
import ClinicManagement from 'modules/ClinicManagement';
import CloudDrive from 'modules/CloudDrive';
import Layout from 'modules/Layout';


async function action({ route, query, getUrl, store }) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  let component = null;
  let redirect = null;

  if (query.code) {
    const decodedStateString = decodeURI(query.state)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    const state = decodedStateString ? JSON.parse(`{"${decodedStateString}"}`) : {};
    if (state.type === 'pwd') {
      component = <AccountSetup.components.AccountSetup isNoAutoRedirect />;
      if (process.env.BROWSER) {
        redirect = getUrl('setup');
      }
    } else if (state.type === 'pwdReAuth') {
      component = (
        <Account.components.AccountSettings
          view="patientProfile"
          views={['settings', 'patientProfile', 'dataSources']}
        />
      );
      if (process.env.BROWSER) {
        redirect = getUrl('account-settings-view', { view: 'patientProfile' });
      }
    } else if (state.type === 'clinic') {
      injectReducer(ClinicManagement.constants.MODULE_ID, ClinicManagement.reducer);
      component = <ClinicManagement.components.AttachCloudDrive />;
      if (process.env.BROWSER) {
        redirect = getUrl('add-drive');
      }
    } else if (startsWith(state.type, 'clinicReAuth')) {
      injectReducer(ClinicManagement.constants.MODULE_ID, ClinicManagement.reducer);
      injectSagas(ClinicManagement.constants.MODULE_ID, ClinicManagement.sagas);
      const props = state.type.split('-');
      const organizationUID = props.slice(1, 6).join('-');
      const clinicSlug = getSlug(props.slice(6).join('-'));
      component = (
        <ClinicManagement.components.ClinicSettings
          organizationUID={organizationUID}
          view="dataSettings"
          views={['memberships', 'phiSettings', 'dataSettings']}
        />
      );
      if (process.env.BROWSER) {
        redirect = getUrl('clinic-settings-view', { clinicSlug, organizationUID, view: 'dataSettings' });
      }
    }
    await store.dispatch(CloudDrive.actions.authorizeCheck(query.code, 'GoogleDrive', query.scope));
  }

  return {
    chunks   : ['account'],
    name     : route.name,
    redirect,
    component: (
      <Layout.components.PlainLayout isNoValidate>
        { component }
      </Layout.components.PlainLayout>
    ),
  };

}

export default action;
