import React from 'react';
import { getAsyncInjectors } from 'helpers/asyncInjectors';
import AccountSetup from 'modules/AccountSetup';
import Layout from 'modules/Layout';
import Account from 'modules/Account';


async function action({ route, store, query, getUrl }) {
  let redirect = null;
  let searchParams = '';

  const { injectSagas } = getAsyncInjectors(store);
  injectSagas(AccountSetup.constants.MODULE_ID, AccountSetup.sagas);
  const state = store.getState();
  const { enrollCode, invitationCode } = query;

  const activeProfileType = Account.selectors.activeProfileType(state);
  const activeProfileMainUrlRoute = Account.selectors.activeProfileMainUrlRoute(state);

  const { name, params } = activeProfileMainUrlRoute;
  if (route.name !== name) {
    if (activeProfileType === Account.constants.PROFILE_TYPES.PWD && enrollCode && invitationCode) {
      searchParams = `?enrollCode=${enrollCode}&invitationCode=${invitationCode}`;
    }
    redirect = `${getUrl(name, params)}${searchParams}`;
  }


  return {
    chunks   : ['account'],
    name     : route.name,
    redirect,
    component: (
      <Layout.components.PlainLayout isLocking>
        <AccountSetup.components.AccountSetup enrollCode={enrollCode} invitationCode={invitationCode} />
      </Layout.components.PlainLayout>
    ),
  };

}

export default action;
